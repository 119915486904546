<template>
  <Layout>
    <section class="csms-manager">

      <!-- Page Title Start -->
      <PageHeader :title="title" />
      <!-- Page Title End -->

      <!-- Summary Start -->
      <div class="row">
        <div class="col-xl-4">
          <SummaryCard :title="$_formatter.convertedTitle('manager', 'approvalManagerStatus')"
                       :items="[
                         { name: $t('common.total'), value: infoItems.approvalStatusTotal },
                         { name: $t('common.roleAdmin'), value: infoItems.approvalAdmin },
                         { name: $t('common.roleHQ'), value: infoItems.approvalHQ },
                         { name: $t('common.roleCP'), value: infoItems.approvalCP }
                       ]" />
        </div>
        <div class="col-xl-4">
          <SummaryCard :title="$_formatter.convertedTitle('manager', 'waitingManagerStatus')"
                       :items="[
                         { name: $t('common.total'), value: infoItems.waitingStatusTotal },
                         { name: $t('common.roleAdmin'), value: infoItems.waitingAdmin },
                         { name: $t('common.roleHQ'), value: infoItems.waitingHQ },
                         { name: $t('common.roleCP'), value: infoItems.waitingCP }
                       ]" />
        </div>
        <div class="col-xl-4">
          <SummaryCard :title="$_formatter.convertedTitle('manager', 'useStopManagerStatus')"
                       :items="[
                         { name: $t('common.total'), value: infoItems.useStopStatusTotal },
                         { name: $t('common.roleAdmin'), value: infoItems.useStopAdmin },
                         { name: $t('common.roleHQ'), value: infoItems.useStopHQ },
                         { name: $t('common.roleCP'), value: infoItems.useStopCP }
                       ]" />
        </div>
      </div>
      <!-- Summary End -->

      <!-- Search Area Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <div class="row">
                <!-- // Search Input -->
                <div class="col-xl-10 ion-mb-mb-100">
                  <div class="row">
                    <div class="col-xl-2" v-if="$_app.user.roles[0] === 'ADMIN'">
                      <label class="form-label" for="manager-search-hq">{{ $t('common.headquarters') }}</label>
                      <multiselect id="manager-search-hq"
                                   v-model="searchForm.hqId"
                                   label="text" track-by="value"
                                   :options="hqOptions"
                                   selectLabel=""
                                   selectedLabel=""
                                   deselectLabel=""
                                   :searchable="false" :allow-empty="false" />
                    </div>
                    <div class="col-xl-2">
                      <label class="form-label" for="manager-search-cp">{{ $t('common.cp') }}</label>
                      <multiselect id="manager-search-cp"
                                   label="text" track-by="value"
                                   v-model="searchForm.cpId"
                                   :options="cpOptions"
                                   selectLabel=""
                                   selectedLabel=""
                                   deselectLabel=""
                                   :searchable="false" :allow-empty="false" />
                    </div>
                    <div class="col-xl-2">
                      <label class="form-label" for="manager-search-id">{{ $t('common.email') }}</label>
                      <input type="email" class="form-control" id="manager-search-id"
                             v-model="searchForm.email" />
                    </div>
                    <div class="col-xl-2">
                      <label class="form-label" for="manager-search-name">{{ $t('common.name') }}</label>
                      <input type="text" class="form-control" id="manager-search-name"
                             v-model="searchForm.name" />
                    </div>
                    <div class="col-xl-2">
                      <label class="form-label" for="manager-search-mobile">{{ $t('manager.mobile') }}</label>
                      <input type="text" class="form-control" id="manager-search-mobile"
                             v-model="searchForm.mobile" />
                    </div>
                    <div class="col-xl-2">
                      <label class="form-label" for="manager-search-status">{{ $t('common.status') }}</label>
                      <multiselect id="manager-search-status"
                                   label="text" track-by="value"
                                   v-model="searchForm.status"
                                   :options="statusOptions"
                                   selectLabel=""
                                   selectedLabel=""
                                   deselectLabel=""
                                   :searchable="false" :allow-empty="false" />
                    </div>
                  </div>
                </div>
                <!-- // Search Button -->
                <div class="col-xl-2 align-content-end">
                  <div class="float-end">
                    <b-button variant="secondary" class="me-3" @click="reset">
                      <i class="uil uil-redo me-2"></i>
                      <span>{{ $t('btn.reset') }}</span>
                    </b-button>
                    <b-button variant="primary" @click="search">
                      <i class="uil uil-search me-2"></i>
                      <span>{{ $t('btn.search') }}</span>
                    </b-button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- Search Area End -->

      <!-- List Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <div class="row mb-3 align-items-end">
                <!-- // List PerPage Count -->
                <div class="col-auto">
                  <PerPageSelector v-model="pagination.perPage"
                                   :option="[10, 25, 50]"
                                   @change="onPerPageChange" />
                </div>
                <!-- // Total Count -->
                <div class="col me-2">
                  <div class="float-end">
                    <total-count :total-count="pagination.totalCount" />
                  </div>
                </div>
              </div>

              <!-- // List -->
              <div class="table-responsive">
                <b-table class="text-nowrap align-middle" bordered hover
                         show-empty :empty-text="$t('manager.noManager')"
                         :fields="fields" :items="items">
                  <template #head()="data">
                    <span v-if="!$_utils.isEmpty(data.label)">{{ $t(`manager.${data.column}`) }}</span>
                  </template>

                  <template #cell(checkbox)="data">
                    <input type="checkbox" class="form-check-input"
                           v-model="selectedManager" :value="data.item.id"
                           :disabled="$_app.user.email === data.item.email">
                  </template>
                  <template #cell(roles)="data">
                    {{ $_app.code.role[data.item.roles] }}
                  </template>
                  <template #cell(hqName)="data">
                    {{ $_utils.isEmpty(data.item.hqBusiness) ? '-' : data.item.hqBusiness.hqName }}
                  </template>
                  <template #cell(cpName)="data">
                    {{ $_utils.isEmpty(data.item.chargePlace) ? '-' : data.item.chargePlace.placeName }}
                  </template>
                  <template #cell(status)="data">
                    {{ $_app.code.accountStatus[data.item.status] }}
                  </template>
                  <template #cell(etc)="data">
                    <b-button size="sm" variant="warning" @click="edit(data.item.id)">
                      <i class="uil uil-edit me-2"></i>
                      <span>{{ $t('btn.edit') }}</span>
                    </b-button>
                  </template>
                </b-table>
              </div>

              <!-- // Pagination -->
              <div class="row mt-3">
                <div class="col-xl-12">
                  <b-pagination size="sm" class="mb-0" align="center"
                                :total-rows="pagination.totalCount"
                                :per-page="pagination.perPage"
                                v-model="pagination.currentPage"
                                @change="onPageChange" />
                </div>
              </div>

              <div class="row">
                <div class="col-xl-12">
                  <div class="float-start">
                    <b-button variant="danger" @click="editStatus()">
                      <i class="uil uil-ban me-2"></i>
                      <span>{{ $t('manager.useStop') }}</span>
                    </b-button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- List End -->

      <edit-modal ref="managerEditModal" :selected-id="selectedId" @callback="getItems" />
    </section>
  </Layout>
</template>

<script>
import { http } from '@/auth-api';
import { loadingMethods } from '@/state/helpers';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';
import Layout from '@/views/layouts/Main.vue';
import PageHeader from '@/components/PageHeader.vue';
import PerPageSelector from '@/views/components/PerPageSelector.vue';
import TotalCount from '@/views/components/TotalCount.vue';
import SummaryCard from '@/views/components/SummaryCard.vue';
import EditModal from '@/views/pages/manager/ManagerEditModal.vue';

export default {
  components: {
    Multiselect, Layout, PageHeader, PerPageSelector, TotalCount, SummaryCard, EditModal
  },
  data() {
    return {
      title: 'managerAccount',
      selectedId: null,
      selectedManager: [],
      searchConditions: [],
      sortDirection: 'DESC',
      pagination: {
        currentPage: 1,
        totalCount : 0,
        perPage: 10
      },
      infoItems: {
        approvalStatusTotal: 0,
        approvalAdmin: 0,
        approvalHQ: 0,
        approvalCP: 0,
        waitingStatusTotal: 0,
        waitingAdmin: 0,
        waitingHQ: 0,
        waitingCP: 0,
        useStopStatusTotal: 0,
        useStopAdmin: 0,
        useStopHQ: 0,
        useStopCP: 0
      },
      hqOptions: [],
      cpOptions: [],
      statusOptions: [],
      searchForm: {
        hqId: { text: i18n.t('common.total'), value: null },
        cpId: { text: i18n.t('common.total'), value: null },
        email: null,
        name: null,
        mobile: null,
        status: { text: i18n.t('common.total'), value: null }
      },
      fields: [
        { key: 'checkbox', label: '', class: 'text-center' },
        { key: 'rnum', label: 'No.', class: 'text-center' },
        { key: 'roles', label: '권한', class: 'text-center' },
        { key: 'email', label: '이메일 ID', class: 'text-center' },
        { key: 'name', label: '이름', class: 'text-center' },
        { key: 'mobile', label: '연락처', class: 'text-center' },
        { key: 'hqName', label: '사업 본부명', class: 'text-center' },
        { key: 'cpName', label: '충전소명', class: 'text-center' },
        { key: 'created', label: '등록일', class: 'text-center' },
        { key: 'status', label: '상태', class: 'text-center' },
        { key: 'etc', label: '', class: 'text-center' }
      ],
      items: null
    }
  },
  created() {
    const vm = this;
    // TODO: 페이지 새로 고침할 때, 상태 옵션을 빨리 못 읽어오는 문제 있음.
    vm.getStatusOptions();
  },
  mounted() {
    const vm = this;

    vm.getHqOptions();
    vm.getCpOptions();

    vm.showOverlay();
    vm.getItems();
  },
  methods: {
    ...loadingMethods,
    getHqOptions() {
      const vm = this;
      const _url = '/onmapi/view/Hq/listAll';

      http.get(_url, {}).then(res => {
        vm.hqOptions = [{ text: i18n.t('common.total'), value: null }];

        res.data.items.forEach(item => {
          vm.hqOptions.push({text: item.hqName, value: item.id});
        });
      }).catch(err => {
        console.error('Headquarters List Data Error :: ', err)
      });
    },
    getCpOptions() {
      const vm = this;
      const _url = '/onmapi/view/ChargePlace/listAll';

      http.get(_url).then(res => {
        vm.cpOptions = [{ text: i18n.t('common.total'), value: null }];

        res.data.items.forEach(item => {
          vm.cpOptions.push({text: item.placeName, value: item.placeId});
        });
      }).catch(err => {
        console.error('Charge Place List Error :: ', err)
      });
    },
    getStatusOptions() {
      const vm = this;

      vm.statusOptions = [];
      if (this.$_app.code.accountStatus) {
        vm.statusOptions = Object.entries(this.$_app.code.accountStatus).map(([key, value]) => ({ text: value, value: key }));
        vm.statusOptions.unshift({ text: i18n.t('common.total'), value: null });
      }
    },
    async getItems() {
      const vm = this;

      await Promise.all([
        vm.getInfoItems(),
        vm.getListItems()
      ]);
      vm.hideOverlay();
    },
    reset() {
      const vm = this;

      vm.searchForm = {
        hqId: { text: i18n.t('common.total'), value: null },
        cpId: { text: i18n.t('common.total'), value: null },
        email: null,
        name: null,
        mobile: null,
        status: { text: i18n.t('common.total'), value: null }
      };
      vm.searchConditions = [];

      vm.getListItems();
    },
    search() {
      const vm = this;

      vm.searchConditions = [];

      if (!this.$_utils.isEmpty(vm.searchForm.hqId.value)) {
        vm.searchConditions.push({ searchKey: 'hqId', searchValue: vm.searchForm.hqId.value });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.cpId.value)) {
        vm.searchConditions.push({ searchKey: 'placeId', searchValue: vm.searchForm.cpId.value });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.email)) {
        vm.searchConditions.push({ searchKey: 'email', searchValue: vm.searchForm.email });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.name)) {
        vm.searchConditions.push({ searchKey: 'name', searchValue: vm.searchForm.name });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.mobile)) {
        vm.searchConditions.push({ searchKey: 'mobile', searchValue: vm.searchForm.mobile });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.status.value)) {
        vm.searchConditions.push({ searchKey: 'status', searchValue: vm.searchForm.status.value });
      }

      vm.pagination.currentPage = 1;
      vm.getListItems();

      /*if (this.$_utils.isEmpty(vm.searchForm.hqId.value)
          && this.$_utils.isEmpty(vm.searchForm.cpId.value)
          && this.$_utils.isEmpty(vm.searchForm.email)
          && this.$_utils.isEmpty(vm.searchForm.name)
          && this.$_utils.isEmpty(vm.searchForm.mobile)
          && this.$_utils.isEmpty(vm.searchForm.status.value)) {
        Swal.fire({
          icon: 'warning',
          title: i18n.t('alert.searchConditions'),
          confirmButtonText: i18n.t('btn.close')
        });
      } else {
        vm.searchConditions = [];

        if (!this.$_utils.isEmpty(vm.searchForm.hqId.value)) {
          vm.searchConditions.push({ searchKey: 'hqId', searchValue: vm.searchForm.hqId.value });
        }

        if (!this.$_utils.isEmpty(vm.searchForm.cpId.value)) {
          vm.searchConditions.push({ searchKey: 'placeId', searchValue: vm.searchForm.cpId.value });
        }

        if (!this.$_utils.isEmpty(vm.searchForm.email)) {
          vm.searchConditions.push({ searchKey: 'email', searchValue: vm.searchForm.email });
        }

        if (!this.$_utils.isEmpty(vm.searchForm.name)) {
          vm.searchConditions.push({ searchKey: 'name', searchValue: vm.searchForm.name });
        }

        if (!this.$_utils.isEmpty(vm.searchForm.mobile)) {
          vm.searchConditions.push({ searchKey: 'mobile', searchValue: vm.searchForm.mobile });
        }

        if (!this.$_utils.isEmpty(vm.searchForm.status.value)) {
          vm.searchConditions.push({ searchKey: 'status', searchValue: vm.searchForm.status.value });
        }

        vm.pagination.currentPage = 1;
        vm.getListItems();
      }*/
    },
    getInfoItems() {
      const vm = this;
      const _url = '/onmapi/view/Summary/account';

      return new Promise((resolve, reject) => {
        http.get(_url, {}).then(res => {
          let _items = res.data.item;

          if (_items.approved && _items.request && _items.reject) {
            vm.infoItems.approvalStatusTotal = _items.approved.count;

            _items.approved.items.forEach(val => {
              if (val.role === 'ADMIN') {
                vm.infoItems.approvalAdmin = val.count;
              } else if (val.role === 'HQ') {
                vm.infoItems.approvalHQ = val.count;
              } else if (val.role === 'CP') {
                vm.infoItems.approvalCP = val.count;
              }
            })

            vm.infoItems.waitingStatusTotal = _items.request.count;

            _items.request.items.forEach(val => {
              if (val.role === 'ADMIN') {
                vm.infoItems.waitingAdmin = val.count;
              } else if (val.role === 'HQ') {
                vm.infoItems.waitingHQ = val.count;
              } else if (val.role === 'CP') {
                vm.infoItems.waitingCP = val.count;
              }
            })

            vm.infoItems.useStopStatusTotal = _items.reject.count;

            _items.reject.items.forEach(val => {
              if (val.role === 'ADMIN') {
                vm.infoItems.useStopAdmin = val.count;
              } else if (val.role === 'HQ') {
                vm.infoItems.useStopHQ = val.count;
              } else if (val.role === 'CP') {
                vm.infoItems.useStopCP = val.count;
              }
            })
          }
          resolve(res);
        }).catch(err => {
          console.error('Manager Info Data Error :: ', err)
          reject(err);
        });
      });
    },
    getListItems() {
      const vm = this;
      const _url = '/onmapi/view/Account/list';

      vm.selectedManager = [];
      return new Promise((resolve, reject) => {
        http.post(_url, {
          page: vm.pagination.currentPage,
          limit: vm.pagination.perPage,
          searchConditions: vm.searchConditions,
          sortCondition: { sortDirection: vm.sortDirection }
        }).then(res => {
          vm.items = res.data.items;
          vm.pagination.totalCount = res.data.totalCount;
          resolve(res);
        }).catch(err => {
          console.error('Manager List Error :: ', err);
          reject(err);
        });
      });
    },
    async onPerPageChange() {
      const vm = this;

      if (vm.pagination.totalCount > 1) {
        vm.pagination.currentPage = 1;
        vm.showOverlay();
        await vm.getListItems();
        vm.hideOverlay();
      }
    },
    async onPageChange(val) {
      const vm = this;
      vm.pagination.currentPage = val;

      if (vm.pagination.totalCount > 1) {
        vm.showOverlay();
        await vm.getListItems();
        vm.hideOverlay();
      }
    },
    edit(id) {
      const vm = this;
      vm.selectedId = id;

      vm.$nextTick(() => {
        this.$refs.managerEditModal.show();
      });
    },
    editStatus() {
      const vm = this;

      if (vm.selectedManager.length < 1) {
        Swal.fire({
          icon: 'error',
          title: i18n.t('alert.selectAdministrator'),
          showConfirmButton: false,
          timer: 3000,
        });
        return false;
      } else {
        Swal.fire({
          title: i18n.t('alert.confirmUseStop'),
          showCancelButton: true,
          confirmButtonText: i18n.t('btn.ok'),
          cancelButtonText: i18n.t('btn.cancel')
        }).then((result) => {
          if (result.isConfirmed) {
            (async () => {
              for (const item of vm.selectedManager) {
                const _url = `/onmapi/view/Account/Reject/${item}`;

                try {
                  await http.patch(_url);
                  console.info('Account Status Edit Success !!', item);
                } catch (err) {
                  console.error('Account Status Edit Error !!', err);

                  await Swal.fire({
                    icon: 'error',
                    title: i18n.t('alert.error'),
                    showConfirmButton: false,
                    timer: 1500,
                  });
                }
              }

              vm.selectedManager = [];

              await vm.getItems();

              await Swal.fire({
                icon: 'info',
                title: i18n.t('alert.edited'),
                confirmButtonText: i18n.t('btn.close')
              });
            })();
          }
        })
      }
    }
  }
}
</script>
