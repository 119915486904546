<template>
  <b-modal header-class="ion-bg-modal-header" size="xl"
           centered scrollable no-close-on-backdrop
           v-model="visible">

    <template #modal-header>
      <div class="fw-bold">{{ $t('manager.managerTitle') }} {{ $t('common.edit') }}</div>
    </template>

    <!-- START Spinner -->
    <div class="spinner-zone" v-if="isLoading">
      <b-spinner variant="primary" style="width: 2.5rem; height: 2.5rem;" />
      <div>Loading...</div>
    </div>
    <!-- END Spinner -->

    <div class="row">
      <div class="col-xl-12">

        <!-- Account Info Start -->
        <div class="ion-modal-form-group-title">{{ $t('manager.accountInfo') }}</div>
        <div class="row mb-3">
          <div class="col-xl-6">
            <div class="btn-group w-100" role="group">
              <input type="radio" class="btn-check" id="radioBtn1" autocomplete="off"
                     v-model="form.roles" value="ADMIN" @click="changeRole('ADMIN')" />
              <label class="btn btn-outline-secondary" for="radioBtn1">{{ $t('common.roleAdmin') }}</label>
              <input type="radio" class="btn-check" id="radioBtn2" autocomplete="off"
                     v-model="form.roles" value="HQ" @click="changeRole('HQ')" />
              <label class="btn btn-outline-secondary" for="radioBtn2">{{ $t('common.roleHQ') }}</label>
              <input type="radio" class="btn-check" id="radioBtn3" autocomplete="off"
                     v-model="form.roles" value="CP" @click="changeRole('CP')" />
              <label class="btn btn-outline-secondary" for="radioBtn3">{{ $t('common.roleCP') }}</label>
            </div>
          </div>
          <div class="col-xl-6"></div>
        </div>
        <div class="row mb-3">
          <div class="col-xl-6">
            <label class="form-label" for="manager-update-id">{{ $t('manager.email') }}</label>
            <b-form-input class="form-control" type="text" id="manager-update-id" v-model="form.email" disabled />
          </div>
          <div class="col-xl-6 align-content-end">
            <b-button variant="warning" @click="resetPwd()" v-if="$_app.user.roles[0] === 'ADMIN'">{{ $t('manager.resetPwd') }}</b-button>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-xl-6">
            <label for="manager-update-name">{{ $t('manager.name') }}</label>
            <input id="manager-update-name"
                   v-model="form.name"
                   type="text"
                   class="form-control"
                   :class="{ 'is-invalid': submitted && $v.form.name.$error }" />
            <div v-if="submitted && $v.form.name.$error" class="invalid-feedback">
              <span v-if="!$v.form.name.required">{{ $t('validation.nameIsRequired') }}</span>
            </div>
          </div>
          <div class="col-xl-6">
            <label for="manager-update-mobile">{{ $t('manager.mobile') }}</label>
            <input id="manager-update-mobile"
                   v-model="form.mobile"
                   type="text"
                   class="form-control"
                   :class="{ 'is-invalid': submitted && $v.form.mobile.$error }" />
            <div v-if="submitted && $v.form.mobile.$error" class="invalid-feedback">
              <span v-if="!$v.form.mobile.required">{{ $t('validation.telIsRequired') }}</span>
            </div>
          </div>
        </div>
        <!-- Account Info End -->

        <!-- Affiliation Info Start -->
        <template v-if="form.roles !== 'ADMIN'">
          <div class="ion-modal-form-group-title">{{ $t('manager.affiliationInfo') }}</div>
          <div class="row mb-4">
            <div class="col-xl-6">
              <label class="form-label" for="manager-update-hq">{{ $t('common.headquarters') }}</label>
              <multiselect id="manager-update-hq"
                           v-model="hqId"
                           label="text"
                           track-by="value"
                           :options="hqOptions"
                           :placeholder="$t('alert.selectHq')"
                           selectedLabel=""
                           :selectLabel="$t('common.select')"
                           :deselectLabel="$t('common.unselect')"
                           :searchable="false" :allow-empty="false"
                           :disabled="$_app.user.roles[0] !== 'ADMIN'"
                           @select="getCpOptions('second')"
                           @remove="removeHq" />
            </div>
            <div class="col-xl-6">
              <template v-if="form.roles === 'CP'">
                <label class="form-label" for="manager-update-charge-place">{{ $t('common.cp') }}</label>
                <multiselect id="manager-update-charge-place"
                             v-model="cpId"
                             label="text"
                             track-by="value"
                             :options="cpOptions"
                             :placeholder="$t('alert.selectCp')"
                             selectedLabel=""
                             selectLabel=""
                             deselectLabel=""
                             :searchable="false" :allow-empty="false" />
              </template>
            </div>
          </div>
        </template>
        <!-- Affiliation Info End -->

        <!-- Approval Info Start -->
        <div class="ion-modal-form-group-title">{{ $t('manager.approvalInfo') }}</div>
        <div class="row mb-3">
          <div class="col-xl-6">
            <div class="btn-group w-100" role="group">
              <input type="radio" class="btn-check" id="radioStatusBtn1" autocomplete="off"
                     v-model="form.status" value="Request" />
              <label class="btn btn-outline-secondary" for="radioStatusBtn1">{{ $t('common.statusRequest') }}</label>
              <input type="radio" class="btn-check" id="radioStatusBtn2" autocomplete="off"
                     v-model="form.status" value="Reject" />
              <label class="btn btn-outline-secondary" for="radioStatusBtn2">{{ $t('common.statusReject') }}</label>
              <input type="radio" class="btn-check" id="radioStatusBtn3" autocomplete="off"
                     v-model="form.status" value="Approved" />
              <label class="btn btn-outline-secondary" for="radioStatusBtn3">{{ $t('common.statusApproved') }}</label>
            </div>
          </div>
          <div class="col-xl-6"></div>
        </div>
        <!-- Approval Info End -->

      </div>
    </div>

    <template #modal-footer>
      <div class="w-100 text-end">
        <b-button variant="secondary" class="w-xs p-1 me-3" @click="close">{{ $t('btn.close') }}</b-button>
        <b-button variant="success" class="w-xs p-1" @click="save">{{ $t('btn.save') }}</b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
import { http } from '@/auth-api';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';
import { required } from 'vuelidate/lib/validators';

export default {
  components: { Multiselect },
  props: {
    selectedId: {
      type: Number
    }
  },
  data() {
    return {
      isLoading: true,
      visible: false,
      submitted: false,
      hqOptions: [],
      cpOptions: [],
      form: {
        roles: null,
        email: null,
        name: null,
        mobile: null,
        status: null
      },
      hqId: null,
      cpId: null,
      item: null
    }
  },
  validations: {
    form: {
      name: { required },
      mobile: { required }
    }
  },
  methods: {
    show() {
      const vm = this;

      vm.isLoading = true;

      vm.getHqOptions();
      vm.visible = true;
    },
    getHqOptions() {
      const vm = this;
      const _url = '/onmapi/view/Hq/listAll';

      http.get(_url, {}).then(res => {
        vm.hqOptions = [];

        res.data.items.forEach(item => {
          vm.hqOptions.push({ text: item.hqName, value: item.id });
        });

        vm.getItem();
      }).then(() => {
        vm.isLoading = false;
      }).catch(err => {
        console.error('Headquarters List Data Error :: ', err)
      });
    },
    getItem() {
      const vm = this;
      const _url = `/onmapi/view/Account/retrieve/${this.selectedId}`;

      http.get(_url).then((res) => {
        vm.item = res.data.item;
        vm.form = Object.assign({}, vm.item);

        if (vm.form.roles !== 'ADMIN') {
          if (vm.form.hqBusiness && vm.form.hqBusiness.hasOwnProperty('id')) {
            vm.hqId = vm.hqOptions.find(item => item.value === vm.form.hqBusiness.id);
          }

          if (vm.form.roles === 'CP') {
            vm.getCpOptions('first');
          }
        }
      }).catch((err) => {
        console.error('Manager Retrieve Error !!', err)
      });
    },
    changeRole(role) {
      const vm = this;

      if (role === 'ADMIN') {
        vm.hqId = null;
        vm.cpId = null;
      } else if (role === 'HQ') {
        vm.cpId = null;
      } else if (role === 'CP') {
        vm.getCpOptions('second');
      }
    },
    getCpOptions(type) {
      const vm = this;
      const _url = '/onmapi/view/ChargePlace/list';

      vm.cpOptions = [];
      vm.cpId = null;

      if (!this.$_utils.isEmpty(vm.hqId)) {
        http.post(_url, {
          page: 1,
          limit: 1000,
          searchConditions: [{ 'searchKey': 'hqId', 'searchValue': vm.hqId.value }],
          sortCondition: { sortDirection: 'DESC' }
        }).then(res => {
          res.data.items.forEach(item => {
            vm.cpOptions.push({text: item.placeName, value: item.placeId});
          });

          if (type === 'first') {
            if (vm.form.chargePlace && vm.form.chargePlace.hasOwnProperty('placeId')) {
              vm.cpId = vm.cpOptions.find(item => item.value === vm.form.chargePlace.placeId);
            }
          }
        }).catch(err => {
          console.error('Charge Place List Error :: ', err)
        });
      }
    },
    removeHq() {
      const vm = this;

      vm.cpOptions = [];
      vm.cpId = null;
    },
    resetPwd() {
      const vm = this;

      Swal.fire({
        title: i18n.t('alert.resetPwd'),
        showCancelButton: true,
        confirmButtonText: i18n.t('btn.ok'),
        cancelButtonText: i18n.t('btn.cancel')
      }).then((result) => {
        if (result.isConfirmed) {
          vm.pwdInit();
        }
      });
    },
    pwdInit() {
      const vm = this;
      const _url = `/onmapi/view/Account/Resetpassword/${this.selectedId}`;

      http.patch(_url).then(res => {
        if (res) {
          Swal.fire({
            icon: 'info',
            title: i18n.t('alert.pwdInit'),
            text: i18n.t('alert.pwdInitMailSent'),
            confirmButtonText: i18n.t('btn.close')
          });
        }
      }).catch(err => {
        console.error('Password Init Error :: ', err)
      });
    },
    close() {
      const vm = this;

      this.$emit('callback');
      vm.resetForm();
      vm.visible = false;
    },
    resetForm() {
      const vm = this;

      vm.form = {
        roles: null,
        email: null,
        name: null,
        mobile: null,
        status: null
      }
      vm.hqId = null;
      vm.cpId = null;
      vm.hqOptions = [];
      vm.cpOptions = [];

      vm.$nextTick(() => {
        this.$v.$reset();
      });
    },
    save() {
      const vm = this;
      vm.submitted = true;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        const vm = this;
        const _url = '/onmapi/view/Account/update';

        let _form = {
          id: this.selectedId,
          name: vm.form.name,
          mobile: vm.form.mobile,
          status: vm.form.status,
          roles: vm.form.roles
        };

        if (!this.$_utils.isEmpty(vm.hqId)) {
          _form['hqId'] = vm.hqId.value;
        }

        if (!this.$_utils.isEmpty(vm.cpId)) {
          _form['placeId'] = vm.cpId.value;
        }

        http.patch(_url, _form).then(res => {
          if (res) {
            Swal.fire({
              icon: 'info',
              title: i18n.t('alert.edited'),
              confirmButtonText: i18n.t('btn.close')
            });
            vm.close();
          }
        }).catch(err => {
          console.error('Account Edit Error :: ', err)
        });
      }
    },
  }
}
</script>

<style src='vue-multiselect/dist/vue-multiselect.min.css'></style>
